import axios from 'axios';
import Papa from 'papaparse';
import { format } from 'date-fns';
import { useAuth } from "../api/AuthContext";
import { createXLSX } from './create_and_download_xlsx';
import TaxAssignment from './TaxAssignment';

const DEBUG = true;

// Get App Url
const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
    throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

// Function to get contact information (first name and last name)
export const getContactInfo = async (contact_id, token) => {
    console.log(`Fetching contact information for contact ID: ${contact_id}`);
    try {
        const response = await axios.get(`${appUrl}/jmrapp/contacts/`, {
            params: { id: contact_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Contact info response:', response.data);

        if (response.data.length > 0) {
            const contact = response.data[0];
            console.log(`Found contact: First Name - ${contact.first_name}, Last Name - ${contact.last_name}`);
            return {
                contact_first_name: contact.first_name || '',
                contact_last_name: contact.last_name || ''
            };
        } else {
            console.warn(`No contact found for ID: ${contact_id}`);
            return {
                contact_first_name: '',
                contact_last_name: ''
            };
        }
    } catch (error) {
        console.error(`Error fetching contact information for contact ID ${contact_id}:`, error);
        return {
            contact_first_name: '',
            contact_last_name: ''
        };
    }
};

// State code to state name mapping
const stateCodeMapping = {
    "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California",
    "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "FL": "Florida", "GA": "Georgia",
    "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa",
    "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland",
    "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri",
    "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey",
    "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio",
    "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina",
    "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont",
    "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming"
};

// Function to get tax rate based on state and county
export const getTaxRate = async (stateId, county, token) => {
    console.log("Starting request to fetch and filter tax rates...");

    if (!stateId && !county) {
        alert("State and County fields are empty. Please provide both.");
        return { rate: 0, name: '', proceed: false };
    } else if (!stateId) {
        alert("State field is empty. Please provide a state.");
        return { rate: 0, name: '', proceed: false };
    } else if (!county) {
        alert("County field is empty. Please provide a county.");
        return { rate: 0, name: '', proceed: false };
    }

    try {
        let upperCaseStateId = stateId.trim().toUpperCase();
        const upperCaseCounty = county.trim().toUpperCase().replace(/\s+/g, ' ');

        if (stateCodeMapping[upperCaseStateId]) {
            upperCaseStateId = stateCodeMapping[upperCaseStateId].toUpperCase();
            console.log(`Converted state code to state name: ${upperCaseStateId}`);
        }

        if (upperCaseStateId === "TEXAS") {
            console.log("State is Texas, returning tax rate as zero.");
            return { rate: 0, name: '', proceed: true };
        }

        const url = `${appUrl}/jmrapp/taxrates/`;
        console.log(`Making GET request to: ${url}`);

        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` },
        });

        const taxRates = response.data;

        const filteredRates = taxRates.filter(taxRate => {
            try {
                const parsedState = typeof taxRate.state === 'string'
                    ? JSON.parse(taxRate.state.replace(/'/g, '"'))
                    : taxRate.state;

                if (!parsedState || !parsedState.refName) {
                    console.warn(`Invalid state data for taxRate ID ${taxRate.id}, skipping.`);
                    return false;
                }

                const stateRefName = parsedState.refName.trim().toUpperCase();
                const countyName = taxRate.county ? taxRate.county.trim().toUpperCase().replace(/\s+/g, ' ') : '';

                return stateRefName === upperCaseStateId && countyName === upperCaseCounty;
            } catch (e) {
                console.error(`Error parsing state for taxRate ID ${taxRate.id}:`, e);
                return false;
            }
        });

        if (filteredRates.length === 0) {
            const userConfirmation = window.confirm(
                "The state and county specified were not found in the database. No tax information will be created with the sales order. Would you like to continue? Please contact projects@jmrservices.com for further information."
            );

            if (!userConfirmation) {
                alert("Process canceled. No tax information will be created.");
                return { rate: 0, name: '', proceed: false };
            }
        }

        if (filteredRates.length > 0) {
            const foundTaxRate = filteredRates[0];
            return {
                rate: foundTaxRate.rate,
                name: foundTaxRate.name || '',
                proceed: true,
            };
        } else {
            return { rate: 0, name: '', proceed: true };
        }
    } catch (error) {
        alert(`An error occurred while fetching tax rates: ${error.message}`);
        return { rate: 0, name: '', proceed: false };
    }
};
export const getInventoryItemName = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/jmrapp/inventoryitems/`, {
            params: { ns_eid: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        
        // Filter for the specific item we want
        const matchingItem = response.data.find(item => item.ns_eid === item_netsuite_id);
        
        if (matchingItem) {
            console.log(`Found matching inventory item for NS ID ${item_netsuite_id}: ${matchingItem.name}`);
            return matchingItem.name;
        } else {
            console.warn(`No matching inventory item found for NS ID: ${item_netsuite_id}`);
            return '';
        }
    } catch (error) {
        console.error(`Error fetching inventory item name for ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get service item name
export const getServiceItemName = async (item_netsuite_id, token) => {
    try {
        console.log(`Fetching service item name for NetSuite ID: ${item_netsuite_id}`);
        const response = await axios.get(`${appUrl}/jmrapp/serviceitems/`, {
            params: { endpoint_id: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        
        if (response.data.length > 0) {
            console.log(`Found service item name for NetSuite ID ${item_netsuite_id}: ${response.data[0].name}`);
            return response.data[0].name;
        } else {
            console.warn(`No service item found for NetSuite ID: ${item_netsuite_id}`);
            return `Unknown Service Item (${item_netsuite_id})`;
        }
    } catch (error) {
        console.error(`Error fetching service item name for ${item_netsuite_id}:`, error);
        return `Error - Service Item (${item_netsuite_id})`;
    }
};


// Function to get inventory item unit type
export const getInventoryItemUnitType = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/jmrapp/inventoryitems/`, {
            params: { ns_eid: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.length > 0 ? response.data[0].unit_type : '';
    } catch (error) {
        console.error(`Error fetching inventory item unit type for ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get service item unit type
export const getServiceItemUnitType = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/jmrapp/serviceitems/`, {
            params: { endpoint_id: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.length > 0 ? response.data[0].unit_type : '';
    } catch (error) {
        console.error(`Error fetching service item unit type for ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get miscellaneous item unit type
export const getMiscellaneousItemUnitType = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/jmrapp/miscellaneous-item/`, {
            params: { ns_eid: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.length > 0 ? response.data[0].unit_type : '';
    } catch (error) {
        console.error(`Error fetching miscellaneous item unit type for ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get service item price from the API when turnkey is false
export const getServiceItemPrice = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/jmrapp/serviceitems/`, {
            params: { endpoint_id: item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.length > 0 ? response.data[0].price : 0;
    } catch (error) {
        console.error(`Error fetching service item price for ${item_netsuite_id}:`, error);
        return 0;
    }
};

// Helper function to generate unique IDs
const generateUniqueId = () => {
    return `ID_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
};

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
};

// Function to get item class from NetSuite
const getInventoryItemClass = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/proxyapp/get_inventory_item_class/`, {
            params: { item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.class || '';
    } catch (error) {
        console.error(`Error fetching class for item ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get item class from NetSuite
const getServiceItemClass = async (item_netsuite_id, token) => {
    try {
        const response = await axios.get(`${appUrl}/proxyapp/get_service_item_class/`, {
            params: { item_netsuite_id },
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.class || '';
    } catch (error) {
        console.error(`Error fetching class for item ${item_netsuite_id}:`, error);
        return '';
    }
};

// Function to get SuiteTalk token
const getSuiteTalkToken = async () => {
    try {
        const response = await axios.get(`${appUrl}/proxyapp/get_suitetalk_token/`);
        return response.data.token;
    } catch (error) {
        console.error('Error fetching SuiteTalk token:', error);
        return null;
    }
};

// Function to get pricing level based on well ID and inventory item ID
const getPricingLevel = async (wellId, inventoryItemId, token) => {
    try {
        const response = await axios.get(
            `${appUrl}/jmrapp/get_inventory_item_pricing_level/`,
            {
                params: { well_id: wellId, inventory_item_id: inventoryItemId },
                headers: { Authorization: `Bearer ${token}` }
            }
        );
        return response.data.pricing_level;
    } catch (error) {
        console.error('Error fetching pricing level:', error);
        return null;
    }
};

// Location mapping
const locationMapping = {
    "San Juan Basin": 9,
    "DJ Basin": 1,
    "Permian": 22,
    "Deleware Basin": 10,
    "Reclamation": 24,
};

const RECLAMATION_SUBPROJECT_TYPE_NS_ID = "3";

// Helper function to find the price for an item based on its NetSuite ID
const getItemPriceFromPricebook = (pricebooks, item) => {
    if (!pricebooks || !Array.isArray(pricebooks)) {
        console.error('Pricebooks array is undefined or not an array.');
        return item.price !== undefined && item.price !== null ? item.price : 0;
    }

    console.log(`Looking for item with NetSuite ID: ${item.ns_eid}`);
    console.log('Inventory Pricebooks:', pricebooks);

    const pricebookEntry = pricebooks.find(entry => {
        console.log(`Checking entry: ${entry.inventory_item} against ${item.ns_eid}`);
        return String(entry.inventory_item) === String(item.ns_eid);
    });

    if (!pricebookEntry) {
        console.warn(`No price found for item NetSuite ID: ${item.ns_eid}`);
        return item.price !== undefined && item.price !== null ? item.price : 0;
    }

    return pricebookEntry ? pricebookEntry.price : (item.price !== undefined && item.price !== null ? item.price : 0);
};

const flattenData = async (data, uniqueId, zeroAllPrices, token) => {
    if (DEBUG) console.log('Original data:', data);
    if (DEBUG) console.log('zeroAllPrices value:', zeroAllPrices);
    const flatData = [];

    console.log('Flattening data with subproject info:', {
        subproject_id: data.subproject_id,
        subproject_type: data.subproject_type,
        subproject_netsuite_id: data.subproject_netsuite_id,
        well_id: data.well?.id,
        well_name: data.well?.name
    });

    let proceed = true;

    // Ensure that data and its properties exist
    if (!data || typeof data !== 'object') {
        console.error('Invalid data structure provided to flattenData');
        return [];
    }

    const isReclamationSubproject = data.subproject?.type_name === 'Reclamation' || 
        data.subproject_type_name === 'Reclamation' || data.well?.subproject_type_name === 'Reclamation';

    const baseRecord = {
        unique_id: uniqueId,
        id: data.id || '',
        date: data.date ? formatDate(data.date) : '',
        end_day: data.is_last_day ? (data.date ? formatDate(data.date) : '') : '',
        ticket_number: data.ticket_number || '',
        status: data.status || '',
        notes: data.notes || '',
        contact: data.contact || null,
        created_by: data.created_by || null,
        well_id: data.subproject?.id || data.well?.id || '',
        well_name: data.subproject?.name || data.well?.name || '',
        well_netsuite_id: data.subproject?.netsuite_id || data.well?.netsuite_id || '',
        project_id: data.subproject?.netsuite_id || data.well?.id || '',
        project_name: `${data.well?.name}${data.subproject?.subproject_type_name ? ` - ${data.subproject.subproject_type_name}` : ''}`,
        project_netsuite_id: data.subproject?.netsuite_id || data.well?.netsuite_id || '',
        subproject_type: data.subproject?.subproject_type_name || '',
        memo: `${data.well?.name}${data.subproject?.subproject_type_name ? ` - ${data.subproject.subproject_type_name}` : ''} - ${data.date || ''}`,
        customer_id: data.well?.customer?.id || '',
        customer_name: data.well?.customer?.name || '',
        customer_ns_eid: data.well?.customer?.ns_iid || '',
        assigned_rig_id: data.well?.assigned_rig?.id || '',
        assigned_rig_name: data.well?.assigned_rig?.name || '',
        Department: data.well?.assigned_rig?.netsuite_id || '',
        Location: isReclamationSubproject 
            ? locationMapping['Reclamation']  // Use Reclamation location for Reclamation subproject type
            : (data.well?.area ? locationMapping[data.well.area] || '' : ''),
        type: '',
        work_assignment_description: '',
        work_assignment_from_time: '',
        work_assignment_to_time: '',
        charge_record_id: '',
        item_id: '',
        item_quantity: '',
        item_netsuite_id: '',
        item_price: '',
        item_class: '',
        item_pricing_level: '',
        item_name: '',
        item_unit_type: '',
        Class: '10',
        turnkey: '',
        amount: '', 
        tax_rate: 0,
        tax_name: '',
    };


    // Detailed logging of baseRecord and well information
    console.log('=== Detailed Well Information Logging ===');
    console.log('Raw Well Data:', data.well);
    console.log('Raw Subproject Data:', {
        subproject_id: data.subproject_id,
        subproject_type: data.subproject_type,
        subproject_netsuite_id: data.subproject_netsuite_id
    });

    console.log('=== Well ID Resolution ===');
    console.log('Attempted well_id values:', {
        subproject_id: data.subproject_id,
        well_id: data.well?.id,
        final_value: data.subproject_id || data.well?.id || ''
    });

    console.log('=== Well Name Resolution ===');
    console.log('Attempted well_name components:', {
        well_name: data.well?.name,
        subproject_type: data.subproject_type,
        final_value: `${data.well?.name} - ${data.subproject_type}` || ''
    });

    console.log('=== NetSuite ID Resolution ===');
    console.log('Attempted netsuite_id values:', {
        subproject_netsuite_id: data.subproject?.netsuite_id,
        well_netsuite_id: data.well?.netsuite_id,
        final_value: data.subproject_netsuite_id || data.well?.netsuite_id || ''
    });

    // Base Record Logging
    console.log('=== Base Record Details ===');
    console.log(JSON.stringify(baseRecord, null, 2));
    
    // Source Data Logging
    console.log('=== Source Data Summary ===');
    console.log('Data ID:', data.id);
    console.log('Customer Info:', {
        id: data.well?.customer?.id,
        name: data.well?.customer?.name,
        ns_iid: data.well?.customer?.ns_iid
    });
    console.log('Rig Info:', {
        id: data.well?.assigned_rig?.id,
        name: data.well?.assigned_rig?.name,
        netsuite_id: data.well?.assigned_rig?.netsuite_id
    });
    console.log('Location:', {
        area: data.well?.area,
        mapped_location: locationMapping[data.well?.area]
    });
        
    // Fetch the tax rate based on state and county
    const taxRateInfo = await getTaxRate(data.well?.state || '', data.well?.county || '', token);

    // If tax rate fetching fails or user chose not to proceed, stop the process
    if (!taxRateInfo.proceed) {
        console.error("User chose not to proceed. Process halted.");
        proceed = false;
        return [];  // Return an empty array to indicate failure
    }

    baseRecord.tax_rate = taxRateInfo.rate;
    baseRecord.tax_name = taxRateInfo.name;

    // Fetch and include contact first and last names
    let contactInfo = { contact_first_name: '', contact_last_name: '' };

    if (data.contact) {
        contactInfo = await getContactInfo(data.contact, token);
        console.log(`Fetched contact info: First Name - ${contactInfo.contact_first_name}, Last Name - ${contactInfo.contact_last_name}`);
    }

    baseRecord.contact_first_name = contactInfo.contact_first_name;
    baseRecord.contact_last_name = contactInfo.contact_last_name;

    if (data.charge_records && Array.isArray(data.charge_records)) {
        console.log('List of charge records:', data.charge_records);

        for (const charge of data.charge_records) {
            const turnkey = charge.on_turnkey || false;
            console.log('Is CR Turnkey: ', turnkey);

            // Handle inventory item charges
            if (charge.inventory_item_charges && Array.isArray(charge.inventory_item_charges)) {
                for (const item of charge.inventory_item_charges) {
                    const itemPrice = (zeroAllPrices || turnkey) ? 0 : getItemPriceFromPricebook(data.well?.inventory_pricebooks || [], item);
                    const pricingLevel = await getPricingLevel(data.well?.id || '', item.inventory_item || '', token);
                    const itemClass = await getInventoryItemClass(item.ns_eid || '', token);
                    const itemName = await getInventoryItemName(item.ns_eid || '', token);
                    const itemUnitType = await getInventoryItemUnitType(item.ns_eid || '', token);
                    const amount = turnkey ? '0' : '';

                    flatData.push({
                        ...baseRecord,
                        type: 'inventory_item_charge',
                        charge_record_id: charge.id || '',
                        item_id: item.inventory_item || '',
                        item_quantity: item.quantity_used || '',
                        item_netsuite_id: item.ns_eid || '',
                        item_price: itemPrice,
                        item_class: itemClass,
                        item_name: itemName || '',
                        item_pricing_level: pricingLevel || '',
                        item_unit_type: itemUnitType || '',
                        item_turnkey: turnkey, 
                        amount: amount,
                        tax_rate: taxRateInfo.rate,
                        tax_name: taxRateInfo.name
                    });
                }
            }

            // Handle service item charges
            if (charge.service_item_charges && Array.isArray(charge.service_item_charges)) {
                for (const item of charge.service_item_charges) {
                    let itemPrice = turnkey ? 0 : await getServiceItemPrice(item.endpoint_id || '', token);
                    const itemClass = '10';
                    const itemName = await getServiceItemName(item.endpoint_id || '', token);
                    const itemUnitType = await getServiceItemUnitType(item.endpoint_id || '', token);
                    const amount = turnkey ? '0' : '';

                    flatData.push({
                        ...baseRecord,
                        type: 'service_item_charge',
                        charge_record_id: charge.id || '',
                        item_id: item.service_item || '',
                        item_quantity: item.quantity_used || '',
                        item_netsuite_id: item.endpoint_id || '',
                        item_price: itemPrice,
                        item_class: itemClass,
                        item_name: itemName || '',
                        item_unit_type: itemUnitType || '',
                        item_turnkey: turnkey,
                        amount: amount,
                        tax_rate: taxRateInfo.rate,
                        tax_name: taxRateInfo.name
                    });
                }
            }

            // Handle miscellaneous item charges
            if (charge.miscellaneous_item_charges && Array.isArray(charge.miscellaneous_item_charges)) {
                for (const item of charge.miscellaneous_item_charges) {
                    const itemPrice = (zeroAllPrices || turnkey) ? 0 : (item.price_at_use || 0);
                    const itemClass = '10';
                    const itemUnitType = await getMiscellaneousItemUnitType(item.endpoint_id || '', token);
                    const amount = turnkey ? '0' : ((itemPrice * (parseFloat(item.quantity_used) || 0)).toFixed(2));

                    flatData.push({
                        ...baseRecord,
                        type: 'miscellaneous_item_charge',
                        charge_record_id: charge.id || '',
                        item_id: item.miscellaneous_item || '',
                        item_quantity: item.quantity_used || '',
                        item_netsuite_id: item.endpoint_id || '',
                        item_price: itemPrice,
                        item_class: itemClass,
                        item_custom_name: item.custom_name || '',
                        item_unit_type: itemUnitType || 'Each',
                        item_turnkey: turnkey,
                        amount: amount,
                        tax_rate: taxRateInfo.rate,
                        tax_name: taxRateInfo.name
                    });
                }
            }
        }
    }

    if (DEBUG) console.log('Flattened data before tax assignment:', flatData);

    // Use the TaxAssignment component to assign tax attributes
    const taxAssignmentResult = TaxAssignment({ dwr: data, items: flatData });
    
    if (DEBUG) console.log('Tax Assignment Result:', taxAssignmentResult);

    // Extract tax information from taxAssignmentResult
    if (Array.isArray(taxAssignmentResult)) {
        const flatDataWithTax = taxAssignmentResult.map((item, index) => ({
            ...flatData[index],
            taxItem: item.taxItem || 'No',
            taxable: item.taxable || 'No'
        }));

        if (DEBUG) {
            console.log('Final Flattened data with tax info:', flatDataWithTax);
            flatDataWithTax.forEach((item, index) => {
                console.log(`Item ${index}: ${item.item_name}, Tax Item: ${item.taxItem}, Taxable: ${item.taxable}`);
            });
        }

        return proceed ? flatDataWithTax : [];
    } else {
        console.error('TaxAssignment did not return an array as expected');
        return [];
    }
};

// Convert to CSV function
const convertToCSV = (data) => {
    if (DEBUG) console.log('Input data for CSV conversion:', data);
    
    if (!Array.isArray(data) || data.length === 0) {
        console.error('Invalid or empty data passed to convertToCSV');
        return '';
    }

    const formattedData = data.map(item => {
        if (DEBUG) console.log('Processing item for CSV:', item);

        if (typeof item !== 'object' || item === null) {
            console.error('Invalid item in data array:', item);
            return null;
        }

        const formattedItem = {
            unique_id: item.unique_id || '',
            id: item.id || '',
            date: item.date || '',
            end_day: item.end_day || '',
            ticket_number: item.ticket_number || '',
            status: item.status || '',
            notes: item.notes || '',
            contact: item.contact || '',
            created_by: item.created_by || '',
            // Update these fields to use subproject ID as primary
            well_id: item.project_id || '',  // Use project_id (which is subproject_id)
            well_name: item.project_name || '',  // Use project_name (which includes subproject type)
            well_netsuite_id: item.project_netsuite_id || '',  // Use project_netsuite_id
            charge_record_id: item.charge_record_id || '',
            Department: item.Department || '',
            Class: item.Class || '',
            Location: item.Location || '',
            item_id: item.item_id || '',
            item_quantity: item.item_quantity || '',
            item_netsuite_id: item.item_netsuite_id || '',
            item_price: item.item_price !== undefined && item.item_price !== null ? item.item_price : '',
            item_class: item.item_class || '',
            item_custom_name: item.item_custom_name || '',
            item_pricing_level: item.item_pricing_level || '',
            item_unit_type: item.item_unit_type || 'A',
            customer_ns_eid: item.customer_ns_eid || '',
            memo: item.memo || `${item.project_name} - ${item.date}`,  // Update memo to use project_name
            turnkey: item.item_turnkey || 'false',
            amount: item.amount || '',
            tax_rate: item.tax_rate || '',
            tax_name: item.tax_name || '',
            tax_item: item.taxItem || 'No',
            taxable: item.taxable || 'No'
        };

        if (DEBUG) console.log('Formatted item for CSV:', formattedItem);

        return formattedItem;
    }).filter(item => item !== null);

    if (DEBUG) console.log('All formatted data for CSV:', formattedData);

    if (formattedData.length === 0) {
        console.error('No valid data after formatting');
        return '';
    }

    const csv = Papa.unparse(formattedData);
    if (DEBUG) console.log('Final CSV content:', csv);
    return csv;
};

// Function to upload CSV
const uploadToNetSuite = async (csvContent, fileName, token) => {
    console.log('Token from authState:', token);
    if (!token) {
        console.error('No token found in authState');
        return false;
    }

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', blob, fileName);

    try {
        const response = await axios.post(`${appUrl}/proxyapp/csv_proxy/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        console.log('File uploaded successfully', response.data);
        return true;
    } catch (error) {
        console.error('Error uploading file:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        }
        return false;
    }
};

export const createAndUploadCSV = async (jsonData, zeroAllPrices, authState, templateFileUrl, targetEndpointID, subprojectId = null) => {
    console.log('Creating CSV with initial data:', {
        well_name: jsonData.well?.name,
        well_id: jsonData.well?.id,
        subproject_id: subprojectId,
        subproject_type: jsonData.subproject_type,
        subproject_netsuite_id: jsonData.subproject_netsuite_id

    });
    
    const uniqueId = generateUniqueId();
    const dataCopy = JSON.parse(JSON.stringify(jsonData));
  
    let token = authState.token;
    if (!token) {
      console.log('Fetching new token...');
      token = await getSuiteTalkToken();
      if (!token) {
        console.error('Failed to retrieve token');
        return false;
      }
    }
  
    // Additional check for items in charge records
    const checkForItems = (data) => {
      return data.charge_records && data.charge_records.some(charge => 
        (charge.inventory_item_charges && charge.inventory_item_charges.length > 0) ||
        (charge.service_item_charges && charge.service_item_charges.length > 0) ||
        (charge.miscellaneous_item_charges && charge.miscellaneous_item_charges.length > 0)
      );
    };
  
    if (!checkForItems(dataCopy)) {
      alert("There are no items in this charge record, cannot submit sales order.");
      return false;
    }
  
    if (zeroAllPrices) {
      dataCopy.charge_records.forEach(charge => {
        if (charge.inventory_item_charges) {
          charge.inventory_item_charges.forEach(item => {
            item.price = 0;
          });
        }
        if (charge.service_item_charges) {
          charge.service_item_charges.forEach(item => {
            item.price = 0;
          });
        }
      });
    }
  
    const flatData = await flattenData(dataCopy, uniqueId, zeroAllPrices, token);
    
    if (DEBUG) {
      console.log('Flat data before CSV conversion:');
      flatData.forEach((item, index) => {
        console.log(`Item ${index}:`, item);
      });
    }
  
    const csvContent = convertToCSV(flatData);
  
    if (csvContent) {
      // Include subproject info in the filename
      const subprojectSuffix = subprojectId ? `_SUB${subprojectId}` : '';
      const fileName = `DWR_${dataCopy.well.name}${subprojectSuffix}_${dataCopy.well.netsuite_id}_${uniqueId}.csv`;
      const uploadSuccess = await uploadToNetSuite(csvContent, fileName, token);
  
      if (uploadSuccess) {
        console.log('CSV upload successful. Waiting 3 seconds before creating XLSX...');
        setTimeout(() => {
          console.log('Creating XLSX...');
          createXLSX(flatData, zeroAllPrices, authState, jsonData.well.customer.ns_iid, targetEndpointID, subprojectId);
        }, 3000);
        return true;
      }
    }
    return false;
  };

export default createAndUploadCSV;
