import React, { useState, useEffect } from "react";
import axios from "axios";
import DWRForm from "./DWRForm";
import "../styles/DWR.css";
import { newExportObjectAsPDF, exportMultipleDWRsAsCombinedPDF } from "../util/NewExportPdf";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useAuth } from "../api/AuthContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Checkbox, TableRow, TableCell, Button, Tabs, Tab, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';

const appUrl = process.env.REACT_APP_APP_URL;

if (!appUrl) {
  throw new Error("REACT_APP_APP_URL is not defined. Please set it in your .env file.");
}

const DWR = ({ wellId, wellStatus, subprojectId, subprojectType, onClose }) => {
  const { authState } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [dwrs, setDwrs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFullDwr, setIsLoadingFullDwr] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDwr, setSelectedDwr] = useState('');
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const token = authState?.token;
  const currentUser = authState?.user;
  const [sortedDwrs, setSortedDwrs] = useState([]);
  const isWellClosed = wellStatus === 'Closed';
  const [subprojectName, setSubprojectName] = useState('');
  const [subprojectNsId, setSubprojectNsId] = useState(null);
  const [subprojectStatus, setSubprojectStatus] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [filteredDwrs, setFilteredDwrs] = useState([]);



  const fetchSubprojectDetails = () => {
    if (!subprojectId) return;
    
    axios.get(`${appUrl}/jmrapp/subprojects/${subprojectId}/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setSubprojectName(response.data.name);
        setSubprojectNsId(response.data.netsuite_id); // Save the NetSuite ID
        setSubprojectStatus(response.data.status);
      })
      .catch((error) => {
        console.error("Error fetching subproject details:", error);
      });
  };

  useEffect(() => {
    if (subprojectId) {
      fetchSubprojectDetails();
    }
  }, [subprojectId]);

  useEffect(() => {
    if (wellId && subprojectId) {
      fetchDWRsForWell();
    }
  }, [wellId, subprojectId]);

  const fetchDWRsForWell = async () => {
    setIsLoading(true);
    try {
      let url = `${appUrl}/jmrapp/dailyworkrecords/?well=${wellId}&detail=true`;  // Add detail=true
      if (subprojectId) {
        url += `&subproject=${subprojectId}`;
      }
  
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const sortedData = response.data.sort((a, b) => 
        new Date(a.date) - new Date(b.date)
      );
      setDwrs(sortedData);
      setSortedDwrs(sortedData);
    } catch (error) {
      console.error("Error fetching DWRs:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };


  const getAdjacentDWR = (currentDwr, direction) => {
    if (!currentDwr) return null;

    const currentIndex = sortedDwrs.findIndex(dwr => dwr.id === currentDwr.id);
    if (currentIndex === -1) return null;

    if (direction === 'next' && currentIndex < sortedDwrs.length - 1) {
      return sortedDwrs[currentIndex + 1];
    } else if (direction === 'prev' && currentIndex > 0) {
      return sortedDwrs[currentIndex - 1];
    }
    return null;
  };

  const fetchFullDWRData = async (dwrId) => {
    try {
      const response = await axios.get(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch full DWR data for ID ${dwrId}:`, error);
      throw error;
    }
  };
  const handlePDFDownload = async (dwr, event) => {
    event.stopPropagation();
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      await newExportObjectAsPDF(fullDwrData, token);
    } catch (error) {
      console.error('Failed to generate PDF:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to generate PDF. Please try again.",
        buttons: [{ label: "OK" }],
      });
    }
  };

  const handleDeleteToggle = () => {
    setIsDeleteMode(!isDeleteMode);
    setSelectedForDeletion([]);
  };

  const handleCancelDelete = () => {
    setIsDeleteMode(false);
    setSelectedForDeletion([]);
  };

  const handleCheckboxChange = (dwrId) => {
    setSelectedForDeletion((prevSelected) =>
      prevSelected.includes(dwrId)
        ? prevSelected.filter((id) => id !== dwrId)
        : [...prevSelected, dwrId]
    );
  };

  const handleDeleteSelectedDWRs = async () => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete the selected DWRs?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await Promise.all(
                selectedForDeletion.map((dwrId) =>
                  axios.delete(`${appUrl}/jmrapp/dailyworkrecords/${dwrId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                  })
                )
              );
              fetchDWRsForWell();
              setIsDeleteMode(false);
              setSelectedForDeletion([]);
            } catch (error) {
              console.error('Failed to delete selected DWRs:', error);
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const StyledTableRow = styled(TableRow)(({ theme, islastday }) => ({
    backgroundColor: islastday === 'true' ? 'rgba(255, 253, 231, 1)' : 'inherit',
    '&:hover': {
      backgroundColor: islastday === 'true' ? 'rgba(255, 253, 231, 0.7)' : theme.palette.action.hover,
    },
  }));
  const handleDwrClick = async (dwr) => {
    setIsLoadingFullDwr(true);
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      setSelectedDwr(fullDwrData);
      
      if (isWellClosed) {
        confirmAlert({
          title: "Well Closed",
          message: "This well is closed. You can view DWRs for closed wells, but you cannot edit them.",
          buttons: [
            {
              label: "View DWR",
              onClick: () => setShowForm(true)
            },
            {
              label: "Cancel",
              onClick: () => setSelectedDwr(null)
            }
          ]
        });
      } else {
        setShowForm(true);
      }
    } catch (error) {
      console.error('Failed to fetch full DWR data:', error);
      confirmAlert({
        title: "Error",
        message: "Failed to load DWR data. Please try again.",
        buttons: [{ label: "OK" }],
      });
    } finally {
      setIsLoadingFullDwr(false);
    }
  };

  const handleCreateNewDwr = () => {
    if (isWellClosed) {
      confirmAlert({
        title: "Well Closed",
        message: "This well is closed. You cannot create new DWRs for closed wells.",
        buttons: [{ label: "OK" }],
      });
    } else if (!subprojectId) {
      confirmAlert({
        title: "No Subproject Selected",
        message: "Please select a subproject before creating a new DWR.",
        buttons: [{ label: "OK" }],
      });
    } else {
      setSelectedDwr(null);
      setShowForm(true);
    }
  };

  const handleToggleForm = () => {
    setSelectedDwr(null);
    setShowForm(false);
    fetchDWRsForWell(); // Fetch DWRs again when the form is closed
    if (onClose) {
      onClose();  // Add this line
    }

  };

  const handleApprovalClick = async (dwr) => {
    try {
      const fullDwrData = await fetchFullDWRData(dwr.id);
      const pdfBlob = await newExportObjectAsPDF(fullDwrData, token, false);
      const pdfFile = new Blob([pdfBlob], { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('pdf', pdfFile, 'dwr.pdf');

      if (currentUser && (currentUser.is_district_manager || currentUser.is_superuser)) {
        await axios.post(appUrl + `/jmrapp/dailyworkrecords/${dwr.id}/send_customer_approval_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to customer successfully');
      } else {
        await axios.post(appUrl + `/jmrapp/dailyworkrecords/${dwr.id}/send_reports_email/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log('Approval request sent to manager successfully');
      }
    } catch (error) {
      console.error('Failed to send approval request:', error);
    }
  };
  const handleCombineAndDownloadPDFs = async () => {
    setIsDownloading(true);
    try {
      const sortedDwrs = [...dwrs].sort((a, b) => new Date(a.date) - new Date(b.date));
      const fullDwrsData = await Promise.all(sortedDwrs.map(dwr => fetchFullDWRData(dwr.id)));
      await exportMultipleDWRsAsCombinedPDF(fullDwrsData, token);
    } catch (error) {
      console.error('Failed to combine and download PDFs:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!dwrs.length) return;
    
    switch (tabValue) {
      case 0: // Approved
        setFilteredDwrs(dwrs.filter(dwr => dwr.is_approved));
        break;
      case 1: // Unapproved
        setFilteredDwrs(dwrs.filter(dwr => !dwr.is_approved));
        break;
      case 2: // All
        setFilteredDwrs(dwrs);
        break;
      default:
        setFilteredDwrs(dwrs);
    }
  }, [tabValue, dwrs]);

  return (
    <>
      {subprojectName && (
        <div className="bg-gray-50 p-4 mb-4 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold text-gray-700">
            Showing DWRs for {subprojectName}
            {subprojectStatus === 'Closed' && (
              <span className="ml-2 text-sm font-normal text-gray-500">(Closed)</span>
            )}
          </h2>
        </div>
      )}

      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              fontSize: '14px',
              minWidth: '120px',
              padding: '8px 16px',
            },
            '& .Mui-selected': {
              color: '#0b6e4f !important',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#0b6e4f',
            }
          }}
        >
          <Tab label="Approved" />
          <Tab label="Unapproved" />
          <Tab label="All" />
        </Tabs>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1rem' }}>
          <Button
            variant="contained"
            onClick={subprojectStatus === 'Closed' ? () => {} : handleCreateNewDwr}
            disabled={isWellClosed || subprojectStatus === 'Closed'}
            sx={{
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: subprojectStatus === 'Closed' ? '#0b6e4f' : '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            {subprojectStatus === 'Closed' ? 'View Closed Project' : 'Create New Form'}
          </Button>
          <Button
            variant="contained"
            onClick={handleCombineAndDownloadPDFs}
            disabled={isDownloading}
            sx={{
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            {isDownloading ? 'Downloading...' : 'Download Combined PDF'}
          </Button>
          {isDeleteMode ? (
            <>
              <Button
                variant="contained"
                onClick={handleDeleteSelectedDWRs}
                sx={{
                  backgroundColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                Delete Selected DWRs
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelDelete}
                sx={{
                  color: '#0b6e4f',
                  borderColor: '#0b6e4f',
                  '&:hover': {
                    backgroundColor: '#11372b',
                    color: '#ffffff',
                    borderColor: '#11372b',
                  },
                  textTransform: 'none',
                  padding: '8px 16px',
                  fontSize: '14px',
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              onClick={handleDeleteToggle}
              sx={{
                backgroundColor: '#0b6e4f',
                '&:hover': {
                  backgroundColor: '#11372b',
                },
                textTransform: 'none',
                padding: '8px 16px',
                fontSize: '14px',
              }}
            >
              Select DWRs for Deletion
            </Button>
          )}
        </div>
      </Box>

      <Divider sx={{ my: 2 }} />

      {showForm && (
        <div>
          <DWRForm
            selectedDwr={selectedDwr}
            setSelectedDwr={setSelectedDwr}
            onClose={handleToggleForm}
            setShowForm={setShowForm}
            onSubmit={() => setShowForm(false)}
            getAdjacentDWR={getAdjacentDWR}
            fetchFullDWRData={fetchFullDWRData}
            subprojectId={subprojectId}
            subprojectType={subprojectType}
            subprojectNsId={subprojectNsId}
          />
          <Button
            variant="contained"
            onClick={handleToggleForm}
            sx={{
              marginTop: '10px',
              backgroundColor: '#0b6e4f',
              '&:hover': {
                backgroundColor: '#11372b',
              },
              textTransform: 'none',
              padding: '8px 16px',
              fontSize: '14px',
            }}
          >
            Close Form
          </Button>
        </div>
      )}

      {!showForm && (
        <div>
          {isLoading ? (
            <p>Loading DWRs...</p>
          ) : error ? (
            <p>Error fetching DWRs: {error.message}</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Notes</th>
                  <th>Approved</th>
                  <th>SO Created</th>
                  <th>Superintendent</th>
                  <th>Supervisor</th>
                  <th>Last Day</th>
                  <th>
                    {currentUser && (currentUser.is_district_manager || currentUser.is_superuser)
                      ? 'Customer Approval'
                      : 'Manager Approval'}
                  </th>   
                  <th>Total Amount</th>               
                  <th>PDF</th>
                  {isDeleteMode && <th>Delete</th>}
                </tr>
              </thead>
              <tbody>
              {filteredDwrs.map((dwr) => (
                  <StyledTableRow key={dwr.id} islastday={dwr.is_last_day.toString()}>
                    <TableCell
                      onClick={() => handleDwrClick(dwr)}
                      className={`clickable-link ${isLoadingFullDwr ? 'loading' : ''}`}
                    >
                      {new Date(dwr.date).toLocaleDateString("en-US", { timeZone: "UTC" })} - {dwr.day}
                    </TableCell>
                    <TableCell>{dwr.notes}</TableCell>
                    <TableCell>
                      <Checkbox checked={!!dwr.is_approved} disabled />
                    </TableCell>
                    <TableCell>
                      <Checkbox checked={!!dwr.is_so_created} disabled />
                    </TableCell>
                    <TableCell>
                      {dwr.primary_manager 
                        ? `${dwr.primary_manager.first_name} ${dwr.primary_manager.last_name}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell>                      
                      {dwr.created_by
                        ? `${dwr.created_by.first_name} ${dwr.created_by.last_name}`
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Checkbox checked={!!dwr.is_last_day} disabled />
                    </TableCell>
                    <TableCell>
                      {dwr.status === 'In Progress' && (
                        <button onClick={() => handleApprovalClick(dwr)}>
                          {currentUser && (currentUser.is_district_manager || currentUser.is_superuser)
                            ? 'Send to Customer'
                            : 'Send to Reports'}
                        </button>
                      )}
                    </TableCell>
                    <TableCell>
                      ${(() => {
                        // If total_amount exists directly on the DWR, use it
                        if (dwr.total_amount !== undefined && dwr.total_amount !== null) {
                          return dwr.total_amount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });
                        }
                        
                        // If no pre-calculated total, calculate from non-turnkey charge records only
                        if (dwr.charge_records && dwr.charge_records.length > 0) {
                          const total = dwr.charge_records
                            .filter(record => !record.on_turnkey)  // Only include non-turnkey records
                            .reduce((sum, record) => {
                              // Ensure we're using the numeric value of total_amount
                              const recordTotal = Number(record.total_amount) || 0;
                              return sum + recordTotal;
                            }, 0);

                          return total.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });
                        }

                        return '0.00';
                      })()}
                    </TableCell>
                    <TableCell>
                      <button onClick={(event) => handlePDFDownload(dwr, event)}>
                        <FileDownloadIcon />
                      </button>
                    </TableCell>
                    {isDeleteMode && (
                      <TableCell>
                        <Checkbox
                          checked={selectedForDeletion.includes(dwr.id)}
                          onChange={() => handleCheckboxChange(dwr.id)}
                        />
                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default DWR;
